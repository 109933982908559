import { useFormik } from 'formik';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import sprite from 'src/assets/img/sprite.svg';
import { adminActions } from 'src/store/actions';

const InfoMain = () => {
  const dispatch = useDispatch();
  const { id, companyName, description, country, city } = useSelector(
    (state) => state.adminReducer.user.company,
  );
  const userErrors = useSelector((state) => state.adminReducer.userErrors);

  const handleSubmit = (values) => {
    dispatch(adminActions.companyUpdateInfo(values));
  };

  const formik = useFormik({
    initialValues: {
      id,
      companyName: companyName || '',
      description: description || '',
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <form className="admin-info-main" onSubmit={formik.handleSubmit}>
      <div className="exp-content__title">
        <h3>Інформація про компанію</h3>
      </div>
      <div className="exp-content__wrap">
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Company id: {id}</div>
            {country && city && (
              <div className="label">
                {country.name}, {city.name}
              </div>
            )}
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">
              Назва компанії <span>*</span>
            </div>
          </div>
          <div className="exp-content__row__right">
            <input
              className={`input ${userErrors.companyName ? 'error' : ''}`}
              type="text"
              name="companyName"
              placeholder="Олександр Александров"
              maxLength="50"
              value={formik.values.companyName}
              required
              onChange={formik.handleChange}
            />
            {userErrors.companyName && <div className="error-text">{userErrors.companyName}</div>}
          </div>
        </div>
        <div className="exp-content__row">
          <div className="exp-content__row__left">
            <div className="label">Діяльність компанії</div>
          </div>
          <div className="exp-content__row__right">
            <div className="textarea">
              <textarea
                value={formik.values.description}
                name="description"
                placeholder="Опишіть діяльність компанії"
                className=""
                maxLength="190"
                onChange={formik.handleChange}
              />
              <div className="textarea__maxlength">190 символів максимум</div>
            </div>
          </div>
        </div>

        <div className="exp-content__row">
          <div className="exp-content__row__left" />
          <div className="exp-content__row__right">
            <button type="button" className="btn btn-primary" onClick={handleShow}>
              Зберегти
            </button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="custom-modal-big custom-modal-big--admin">
            <button type="button" className="custom-modal__close">
              <svg width="20" height="20">
                <use xlinkHref={`${sprite}#modalClose`} />
              </svg>
            </button>
            <div className="not-auth__title">Ви дійсно хочете внести зміни?</div>
            <div className="not-auth__btn">
              <button
                type="submit"
                className="btn btn-primary btn--new"
                onClick={() => {
                  formik.handleSubmit();
                  handleClose();
                }}
              >
                Так
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn--new"
                onClick={() => {
                  formik.handleReset();
                  handleClose();
                }}
              >
                Ні
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </form>
  );
};

export default InfoMain;
