import axios from 'axios';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { MultilineInput } from 'react-input-multiline';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import avatarDefault from 'src/assets/img/proekt/avatar-default.jpg';
import PfdLabel from 'src/assets/img/resume/2.png';
import sprite from 'src/assets/img/sprite.svg';
import { Loader } from 'src/components';
import { getNewData, useQuery } from 'src/helpers';
import { useTranslation } from 'src/hooks';
import { SocketContext } from 'src/socketContext';
import { chatActions } from 'src/store/actions';
import { addMess, removeMessCount } from 'src/store/reducers/chat.reducer';

const ChatExpert = () => {
  const socket = useContext(SocketContext);
  const { translate } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const { id } = useParams();

  const myUserId = useSelector((state) => state.authReducer.user.id);
  const waiter = useSelector((state) => state.chatReducer.waiter);
  const waiterChat = useSelector((state) => state.chatReducer.waiterChat);

  const [newMess, setNewMess] = useState('');
  const companyId = useSelector((state) => state.authReducer.user.company.id);
  const chatList = useSelector((state) => state.chatReducer.chatList);
  const thisChat = chatList.length > 0 ? chatList.filter((item) => item.id === Number(id))[0] : 0;
  const chat = useSelector((state) => state.chatReducer.chat);
  const listRef = useRef();
  const fileRef = useRef();
  const expertId = Number(query.get('expert'));
  const projectId = Number(query.get('project'));
  const myAvatar = useSelector((state) => state.authReducer.user.company.avatar);
  const expertAvatar = thisChat && thisChat.expert.avatar;

  const [file, setFile] = useState(false);

  useEffect(() => {
    if (thisChat && thisChat.unreadByExpertCount) {
      dispatch(removeMessCount());
    }
  }, [thisChat]);

  const handleReedMess = () => {
    dispatch(chatActions.companyReadMess(id));
  };

  useEffect(() => {
    dispatch(chatActions.getChatCompany(id));
    handleReedMess();
  }, [id]);

  const handleSendMess = (values) => {
    dispatch(chatActions.sendMessCompany({ values, file }));
    document.getElementById('muliti-input').innerHTML = null;
    document.getElementById('muliti-input').focus();
    document.getElementById('muliti-input').blur();
    setFile(false);
  };

  const formik = useFormik({
    initialValues: {
      body: '',
      expertId,
      projectId,
    },
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      handleSendMess(values);
      actions.resetForm({
        values: {
          body: '',
        },
      });
    },
  });

  const clear = () => {
    if (fileRef.current) {
      fileRef.current.value = null;
    }

    setFile(false);
  };

  const handleFile = (event) => {
    const res = event.target.files[0];
    setFile(res);
  };

  useEffect(() => {
    socket.on('connect', () => {});
    socket.emit('subscribe', {
      chatId: id,
      companyId,
      expertId,
    });
    socket.on(`chat.${id}`, (data) => {
      setNewMess(data);
      handleReedMess();
    });

    return () => {
      socket.off('connect');
    };
  }, [id]);

  useEffect(() => {
    if (newMess) {
      dispatch(addMess(newMess));
    }
  }, [newMess]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [waiterChat, waiter, chat]);

  const handleDownload = (url, fileName) => {
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const blob = new Blob([response.data]);
      saveAs(blob, fileName || 'filename.null');
    });
  };

  return (
    <>
      <div className="exp-content__top exp-content__top__mess">
        {thisChat && thisChat.expert ? thisChat.expert.profileName : <div />}
        <Link to="/messages/">{translate('Chat.allChats')}</Link>
      </div>
      <div className="proekt__content__app proekt__content__app--mess">
        {waiter ? (
          <Loader />
        ) : (
          <div className="chatDetail">
            <div className="chatDetail__body" ref={listRef}>
              {chat.length > 0 &&
                chat.map((item) => (
                  <div
                    key={item.id}
                    className={`chatDetail__body__item ${
                      item.userId === myUserId ? 'chatDetail__body__item--my' : ''
                    }`}
                  >
                    {item.userId === myUserId ? (
                      <div
                        className="chatDetail__avatar"
                        style={{
                          backgroundImage: `url(${myAvatar || avatarDefault})`,
                        }}
                      />
                    ) : (
                      <div
                        className="chatDetail__avatar"
                        style={{
                          backgroundImage: `url(${expertAvatar || avatarDefault})`,
                        }}
                      />
                    )}

                    <div className="chatDetail__mess">
                      {item.body && <div className="chatDetail__mess__text">{item.body}</div>}
                      {item.fileUrl && (
                        <button
                          type="button"
                          className="chatDetail__mess__file"
                          onClick={() => handleDownload(item.fileUrl, item.fileName)}
                        >
                          <img src={PfdLabel} alt="img" />
                          <svg width="16" height="16">
                            <use xlinkHref={`${sprite}#chatDownload`} />
                          </svg>
                        </button>
                      )}

                      <div className="chatDetail__mess__data">{getNewData(item.created)}</div>
                    </div>
                  </div>
                ))}
              {waiter && <Loader />}
            </div>
            <form className="chatDetail__bot" onSubmit={formik.handleSubmit}>
              <div className="chatDetail__bot__wrap">
                <button className="chatDetail__send" type="submit">
                  {waiterChat ? (
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        fill="none"
                        stroke="#092c9e"
                        strokeWidth="10"
                        r="35"
                        strokeDasharray="164.93361431346415 56.97787143782138"
                      >
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          repeatCount="indefinite"
                          dur="1s"
                          values="0 50 50;360 50 50"
                          keyTimes="0;1"
                        />
                      </circle>
                    </svg>
                  ) : (
                    <svg width="20" height="19">
                      <use xlinkHref={`${sprite}#chatSend`} />
                    </svg>
                  )}
                </button>
                <label type="button" className="chatDetail__file" htmlFor="chatFile">
                  <svg width="14" height="20">
                    <use xlinkHref={`${sprite}#chatFile`} />
                  </svg>
                  <input
                    type="file"
                    hidden
                    id="chatFile"
                    ref={fileRef}
                    onChange={handleFile}
                    accept="image/png, image/gif, image/jpeg, application/pdf"
                  />
                </label>

                <MultilineInput
                  id="muliti-input"
                  placeholder={translate('Chat.enterMess')}
                  name="body"
                  required
                  value={formik.values.body}
                  onChange={(e) => formik.setFieldValue('body', e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter' && e.target.value.length > 0) {
                      e.preventDefault();
                      formik.handleSubmit();
                    }
                  }}
                />
              </div>
              {file && (
                <div className="chatDetail__bot__file">
                  <img src={PfdLabel} alt="img" />
                  <div className="text">{file.name}</div>

                  <button type="button" onClick={clear}>
                    <svg width="20" height="20">
                      <use xlinkHref={`${sprite}#modalClose`} />
                    </svg>
                  </button>
                </div>
              )}
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatExpert;
