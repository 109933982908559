import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import avatarDefaultComp from 'src/assets/img/avatar-default-comp.png';
import sprite from 'src/assets/img/sprite.svg';
import { FavoriteExpertModal, Loader, RejectExpertModal } from 'src/components';
import { useTranslation } from 'src/hooks';
import { projectActions } from 'src/store/actions';
import { setExpertCurrent, setShowChooseExpertModal } from 'src/store/reducers/project.reducer';

const Offers = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tab, setTab] = useState(1);
  const project = useSelector((state) => state.projectReducer.myProjectDetail);

  const projectCandidates = useSelector((state) => state.projectReducer.projectCandidates);

  const projectFavoriteCandidates = useSelector(
    (state) => state.projectReducer.projectFavoriteCandidates,
  );
  const projectRejectedCandidates = useSelector(
    (state) => state.projectReducer.projectRejectedCandidates,
  );
  const invitedExperts = useSelector((state) => state.projectReducer.invitedExperts);

  const waiter = useSelector((state) => state.projectReducer.myProjectDetailWaiter);
  const waiterMore = useSelector((state) => state.projectReducer.myProjectDetailWaiterMore);

  const [showDetail, setShowDetail] = useState([]);
  const addDetail = (expertId) => {
    setShowDetail([...showDetail, expertId]);
  };
  const removeDetail = (expertId) => {
    setShowDetail(showDetail.filter((item) => item !== expertId));
  };

  useEffect(() => {
    if (tab === 1) {
      dispatch(projectActions.getProjectCandidates({ project: project.id, page: 1 }));
    } else if (tab === 2) {
      dispatch(projectActions.getProjectFavoriteCandidates({ project: project.id, page: 1 }));
    } else if (tab === 3) {
      dispatch(projectActions.getProjectRejectedCandidates({ project: project.id, page: 1 }));
    } else if (tab === 4) {
      dispatch(projectActions.getInvitedExperts({ project: project.id, page: 1 }));
    }
  }, [tab]);

  const invitedExpertsPag = useSelector((state) => state.projectReducer.invitedExpertsPag);

  const loadMoreInvited = () => {
    dispatch(
      projectActions.getInvitedExpertsMore({
        project: project.id,
        page: invitedExpertsPag.current_page + 1,
      }),
    );
  };

  const projectCandidatesPag = useSelector((state) => state.projectReducer.projectCandidatesPag);
  const loadMoreCandidates = () => {
    dispatch(
      projectActions.getProjectCandidatesMore({
        project: project.id,
        page: projectCandidatesPag.current_page + 1,
      }),
    );
  };

  const projectFavoriteCandidatesPag = useSelector(
    (state) => state.projectReducer.projectFavoriteCandidatesPag,
  );
  const loadMoreFavoriteCandidates = () => {
    dispatch(
      projectActions.getProjectFavoriteCandidatesMore({
        project: project.id,
        page: projectFavoriteCandidatesPag.current_page + 1,
      }),
    );
  };

  const projectRejectedCandidatesPag = useSelector(
    (state) => state.projectReducer.projectRejectedCandidatesPag,
  );
  const loadMoreRejectedCandidates = () => {
    dispatch(
      projectActions.getProjectRejectedCandidatesMore({
        project: project.id,
        page: projectFavoriteCandidatesPag.current_page + 1,
      }),
    );
  };

  // reject and favorite
  const [handleRejectModal, setHandleRejectModal] = useState(0);
  const [isReject, setIsReject] = useState(false);
  const [rejectModalXY, setRejectModalXY] = useState({ pageX: 0, pageY: 0 });

  const [handleFavoriteModal, setHandleFavoriteModal] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteModalXY, setFavoriteModalXY] = useState({ pageX: 0, pageY: 0 });

  const handleReject = (id) => {
    dispatch(projectActions.projectDetailRejectExpert({ expert: id, project: project.id, tab }));
  };

  const handleFavorite = (id) => {
    dispatch(projectActions.projectDetailFavoriteExpert({ expert: id, project: project.id, tab }));
  };

  const handleCreateChat = async (expertId) => {
    const res = await dispatch(projectActions.createChat({ expertId, projectId: project.id }));
    navigate(
      `/messages/${res.payload.data.id}?expert=${res.payload.data.expert.id}&project=${project.id}`,
    );
  };

  return (
    <>
      <div className="proekt__offers">
        <div className="Proekt__offers__title">{translate('My-project.offers')}</div>
        <div className="proekt__tabs tabs-nav" data-tabs="#proect-tabs">
          <ul>
            <li>
              <button type="button" className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
                {translate('My-project.offersTab1')}{' '}
                {projectCandidates.length > 0 && `(${projectCandidates.length})`}
              </button>
            </li>
            <li>
              <button type="button" className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
                {translate('My-project.offersTab2')}
              </button>
            </li>
            <li>
              <button type="button" className={tab === 3 ? 'active' : ''} onClick={() => setTab(3)}>
                {translate('My-project.offersTab3')}
              </button>
            </li>
            <li>
              <button type="button" className={tab === 4 ? 'active' : ''} onClick={() => setTab(4)}>
                {translate('My-project.offersTab4')}
              </button>
            </li>
          </ul>
        </div>

        {tab === 1 && (
          <div style={{ minHeight: '100px' }}>
            {waiter ? (
              <Loader />
            ) : (
              <>
                <div className="proekt__content__wrap">
                  {projectCandidates.length > 0 ? (
                    projectCandidates.map((item) => (
                      <div className="proekt__list__item" key={item.id}>
                        <div className="proekt__detail__flex">
                          <div className="left">
                            <div className="proekt__content__top">
                              <div className="proekt__content__left">
                                <div className="proekt__content__person proekt__content__person--list">
                                  <Link
                                    className="proekt__content__avatar"
                                    to={`/expert/${item.expert.slug}`}
                                    style={{
                                      backgroundImage: `url(${
                                        item.expert.avatar || avatarDefaultComp
                                      })`,
                                    }}
                                  />
                                  <div className="proekt__content__person__right">
                                    <Link
                                      to={`/expert/${item.expert.slug}`}
                                      className="proekt__content__person__name"
                                    >
                                      {item.expert.profileName}
                                    </Link>
                                    <div className="proekt__tags">
                                      {item.isInvited ? (
                                        <>
                                          {item.expert.costPerProject && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.expert.costPerProject} {translate('grnPr')}
                                              </span>
                                            </div>
                                          )}

                                          {item.expert.costPerHour && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.expert.costPerHour} {translate('grnHour')}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item.bid && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.bid} {translate('grnPr')}
                                              </span>
                                            </div>
                                          )}
                                          {item.completionTime && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.completionTime} {translate('days')}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      )}

                                      {item.expert.businessCategories.length > 0 &&
                                        item.expert.businessCategories.map((cat) => (
                                          <div className="proekt__tags__item" key={cat.id}>
                                            <span className="tags-point">{cat.name}</span>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {item.expert.services && (
                              <div className="proekt__content__text">{item.expert.services}</div>
                            )}
                          </div>
                          <div className="right">
                            <div className="proekt__offers__right">
                              <div className="proekt__actios">
                                <button
                                  type="button"
                                  className={item.isDisliked ? 'active' : ''}
                                  onClick={(e) => {
                                    setRejectModalXY({
                                      pageX: e.pageX,
                                      pageY: e.pageY,
                                    });
                                    setIsReject(item.isDisliked);
                                    setHandleRejectModal(item.expert.id);
                                  }}
                                >
                                  <svg width="13.2px" height="13.2px">
                                    <use xlinkHref={`${sprite}#dislike`} />
                                  </svg>
                                  <svg width="13.2px" height="13.2px" className="active-svg">
                                    <use xlinkHref={`${sprite}#dislike-active`} />
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  className={item.isLiked ? 'active' : ''}
                                  onClick={(e) => {
                                    setFavoriteModalXY({
                                      pageX: e.pageX,
                                      pageY: e.pageY,
                                    });
                                    setIsFavorite(item.isLiked);
                                    setHandleFavoriteModal(item.expert.id);
                                  }}
                                >
                                  <svg width="13.2px" height="13.2px">
                                    <use xlinkHref={`${sprite}#like`} />
                                  </svg>
                                  <svg width="13.2px" height="13.2px" className="active-svg">
                                    <use xlinkHref={`${sprite}#like-active`} />
                                  </svg>
                                </button>
                              </div>
                              {item.chatId ? (
                                <Link
                                  to={`/messages/${item.chatId}?expert=${item.expert.id}&project=${project.id}`}
                                  className="btn btn-primary proekt__offers__btn-chat"
                                >
                                  {translate('inMess')}
                                </Link>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary proekt__offers__btn-chat"
                                  onClick={() => handleCreateChat(item.expert.id)}
                                >
                                  {translate('inMess')}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="proekt__content__tags">
                          {item.expert.secondCategories.length > 0 &&
                            item.expert.secondCategories.map((cat) => (
                              <div className="proekt__content__tags__item" key={cat.id}>
                                {cat.name}
                              </div>
                            ))}
                          {item.expert.thirdCategories.length > 0 &&
                            item.expert.thirdCategories.map((cat) => (
                              <div className="proekt__content__tags__item" key={cat.id}>
                                {cat.name}
                              </div>
                            ))}
                        </div>
                        <div className="proekt__content__bot">
                          <div className="proekt__location">
                            <svg width="14" height="20">
                              <use xlinkHref={`${sprite}#location`} />
                            </svg>
                            {item.expert.country && item.expert.country.name},{' '}
                            {item.expert.city && item.expert.city.name}
                          </div>
                        </div>
                        {showDetail.includes(item.expert.id) && !item.isInvited && (
                          <div className="proekt__offers__detail mt-2">
                            {item.transmittalLetter && (
                              <>
                                <div className="my-offers__bot__title">
                                  {translate('My-project.letter')}
                                </div>
                                <div className="my-offers__bot__text">{item.transmittalLetter}</div>
                              </>
                            )}

                            {item.screeningQuestions.length > 0 && (
                              <>
                                <div className="my-offers__bot__title">
                                  {translate('My-project.askLatter')}
                                </div>
                                {item.screeningQuestions.map((scr) => (
                                  <div className="my-offers__src" key={scr.id}>
                                    <div className="my-offers__src__title">{scr.text}</div>
                                    <div className="my-offers__bot__text">{scr.pivot.answer}</div>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        )}

                        <div className="proekt__offers__bot">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => {
                              dispatch(setExpertCurrent(item));
                              dispatch(setShowChooseExpertModal(true));
                            }}
                          >
                            {translate('My-project.chooseExicutor')}
                          </button>
                          {!item.isInvited &&
                            item.transmittalLetter &&
                            item.screeningQuestions.length > 0 && (
                              <>
                                {showDetail.includes(item.expert.id) ? (
                                  <button
                                    className="proekt__chat"
                                    type="button"
                                    onClick={() => removeDetail(item.expert.id)}
                                  >
                                    {translate('showLess')}
                                    <svg width="44" height="21">
                                      <use xlinkHref={`${sprite}#detail`} />
                                    </svg>
                                  </button>
                                ) : (
                                  <button
                                    className="proekt__chat"
                                    type="button"
                                    onClick={() => addDetail(item.expert.id)}
                                  >
                                    {translate('detail')}
                                    <svg width="44" height="21">
                                      <use xlinkHref={`${sprite}#detail`} />
                                    </svg>
                                  </button>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="mt-2">{translate('My-project.emptyOffers')}</div>
                  )}
                </div>
                {waiterMore ? (
                  <Loader />
                ) : (
                  <>
                    {projectCandidatesPag.current_page !== projectCandidatesPag.last_page && (
                      <div className="proekt__more">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={loadMoreCandidates}
                        >
                          {translate('My-project.addMore')}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}

        {tab === 2 && (
          <div style={{ minHeight: '100px' }}>
            {waiter ? (
              <Loader />
            ) : (
              <>
                <div className="proekt__content__wrap">
                  {projectFavoriteCandidates.length > 0 ? (
                    projectFavoriteCandidates.map((item) => (
                      <div className="proekt__list__item" key={item.id}>
                        <div className="proekt__detail__flex">
                          <div className="left">
                            <div className="proekt__content__top">
                              <div className="proekt__content__left">
                                <div className="proekt__content__person proekt__content__person--list">
                                  <Link
                                    className="proekt__content__avatar"
                                    to={`/expert/${item.expert.slug}`}
                                    style={{
                                      backgroundImage: `url(${
                                        item.expert.avatar || avatarDefaultComp
                                      })`,
                                    }}
                                  />
                                  <div className="proekt__content__person__right">
                                    <Link
                                      to={`/expert/${item.expert.slug}`}
                                      className="proekt__content__person__name"
                                    >
                                      {item.expert.profileName}
                                    </Link>
                                    <div className="proekt__tags">
                                      {item.isInvited ? (
                                        <>
                                          {item.expert.costPerProject && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.expert.costPerProject} {translate('grnPr')}
                                              </span>
                                            </div>
                                          )}

                                          {item.expert.costPerHour && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.expert.costPerHour} {translate('grnHour')}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item.bid && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.bid} {translate('grnPr')}
                                              </span>
                                            </div>
                                          )}
                                          {item.completionTime && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.completionTime} {translate('days')}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      )}

                                      {item.expert.businessCategories.length > 0 &&
                                        item.expert.businessCategories.map((cat) => (
                                          <div className="proekt__tags__item" key={cat.id}>
                                            <span className="tags-point">{cat.name}</span>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {item.expert.services && (
                              <div className="proekt__content__text">{item.expert.services}</div>
                            )}
                          </div>
                          <div className="right">
                            <div className="proekt__offers__right">
                              <div className="proekt__actios">
                                <button
                                  type="button"
                                  className={item.isDisliked ? 'active' : ''}
                                  onClick={(e) => {
                                    setRejectModalXY({
                                      pageX: e.pageX,
                                      pageY: e.pageY,
                                    });
                                    setIsReject(item.isDisliked);
                                    setHandleRejectModal(item.expert.id);
                                  }}
                                >
                                  <svg width="13.2px" height="13.2px">
                                    <use xlinkHref={`${sprite}#dislike`} />
                                  </svg>
                                  <svg width="13.2px" height="13.2px" className="active-svg">
                                    <use xlinkHref={`${sprite}#dislike-active`} />
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  className={item.isLiked ? 'active' : ''}
                                  onClick={(e) => {
                                    setFavoriteModalXY({
                                      pageX: e.pageX,
                                      pageY: e.pageY,
                                    });
                                    setIsFavorite(item.isLiked);
                                    setHandleFavoriteModal(item.expert.id);
                                  }}
                                >
                                  <svg width="13.2px" height="13.2px">
                                    <use xlinkHref={`${sprite}#like`} />
                                  </svg>
                                  <svg width="13.2px" height="13.2px" className="active-svg">
                                    <use xlinkHref={`${sprite}#like-active`} />
                                  </svg>
                                </button>
                              </div>
                              {item.chatId ? (
                                <Link
                                  to={`/messages/${item.chatId}?expert=${item.expert.id}&project=${project.id}`}
                                  className="btn btn-primary proekt__offers__btn-chat"
                                >
                                  {translate('inMess')}
                                </Link>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary proekt__offers__btn-chat"
                                  onClick={() => handleCreateChat(item.expert.id)}
                                >
                                  {translate('inMess')}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="proekt__content__tags">
                          {item.expert.secondCategories.length > 0 &&
                            item.expert.secondCategories.map((cat) => (
                              <div className="proekt__content__tags__item" key={cat.id}>
                                {cat.name}
                              </div>
                            ))}
                          {item.expert.thirdCategories.length > 0 &&
                            item.expert.thirdCategories.map((cat) => (
                              <div className="proekt__content__tags__item" key={cat.id}>
                                {cat.name}
                              </div>
                            ))}
                        </div>
                        <div className="proekt__content__bot">
                          <div className="proekt__location">
                            <svg width="14" height="20">
                              <use xlinkHref={`${sprite}#location`} />
                            </svg>
                            {item.expert.country && item.expert.country.name},{' '}
                            {item.expert.city && item.expert.city.name}
                          </div>
                        </div>
                        {showDetail.includes(item.expert.id) && !item.isInvited && (
                          <div className="proekt__offers__detail mt-2">
                            <div className="my-offers__bot__title">
                              {translate('My-project.letter')}
                            </div>
                            <div className="my-offers__bot__text">{item.transmittalLetter}</div>
                            {item.screeningQuestions && (
                              <>
                                <div className="my-offers__bot__title">
                                  {translate('My-project.askLatter')}
                                </div>
                                {item.screeningQuestions.map((scr) => (
                                  <div className="my-offers__src" key={scr.id}>
                                    <div className="my-offers__src__title">{scr.text}</div>
                                    <div className="my-offers__bot__text">{scr.pivot.answer}</div>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        )}

                        <div className="proekt__offers__bot">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => {
                              dispatch(setExpertCurrent(item));
                              dispatch(setShowChooseExpertModal(true));
                            }}
                          >
                            {translate('My-project.chooseExicutor')}
                          </button>

                          {!item.isInvited && (
                            <>
                              {showDetail.includes(item.expert.id) ? (
                                <button
                                  className="proekt__chat"
                                  type="button"
                                  onClick={() => removeDetail(item.expert.id)}
                                >
                                  {translate('showLess')}
                                  <svg width="44" height="21">
                                    <use xlinkHref={`${sprite}#detail`} />
                                  </svg>
                                </button>
                              ) : (
                                <button
                                  className="proekt__chat"
                                  type="button"
                                  onClick={() => addDetail(item.expert.id)}
                                >
                                  {translate('detail')}
                                  <svg width="44" height="21">
                                    <use xlinkHref={`${sprite}#detail`} />
                                  </svg>
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="mt-2">{translate('My-project.emptyFav')}</div>
                  )}
                </div>
                {waiterMore ? (
                  <Loader />
                ) : (
                  <>
                    {projectFavoriteCandidatesPag.current_page !==
                      projectFavoriteCandidatesPag.last_page && (
                      <div className="proekt__more">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={loadMoreFavoriteCandidates}
                        >
                          {translate('My-project.addMore')}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}

        {tab === 3 && (
          <div style={{ minHeight: '100px' }}>
            {waiter ? (
              <Loader />
            ) : (
              <>
                <div className="proekt__content__wrap">
                  {projectRejectedCandidates.length > 0 ? (
                    projectRejectedCandidates.map((item) => (
                      <div className="proekt__list__item" key={item.id}>
                        <div className="proekt__detail__flex">
                          <div className="left">
                            <div className="proekt__content__top">
                              <div className="proekt__content__left">
                                <div className="proekt__content__person proekt__content__person--list">
                                  <Link
                                    className="proekt__content__avatar"
                                    to={`/expert/${item.expert.slug}`}
                                    style={{
                                      backgroundImage: `url(${
                                        item.expert.avatar || avatarDefaultComp
                                      })`,
                                    }}
                                  />
                                  <div className="proekt__content__person__right">
                                    <Link
                                      to={`/expert/${item.expert.slug}`}
                                      className="proekt__content__person__name"
                                    >
                                      {item.expert.profileName}
                                    </Link>
                                    <div className="proekt__tags">
                                      {item.isInvited ? (
                                        <>
                                          {item.expert.costPerProject && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.expert.costPerProject} {translate('grnPr')}
                                              </span>
                                            </div>
                                          )}

                                          {item.expert.costPerHour && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.expert.costPerHour} {translate('grnHour')}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item.bid && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.bid} {translate('grnPr')}
                                              </span>
                                            </div>
                                          )}
                                          {item.completionTime && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.completionTime} {translate('days')}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      )}

                                      {item.expert.businessCategories.length > 0 &&
                                        item.expert.businessCategories.map((cat) => (
                                          <div className="proekt__tags__item" key={cat.id}>
                                            <span className="tags-point">{cat.name}</span>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {item.expert.services && (
                              <div className="proekt__content__text">{item.expert.services}</div>
                            )}
                          </div>
                          <div className="right">
                            <div className="proekt__offers__right">
                              <div className="proekt__actios">
                                <button
                                  type="button"
                                  className={item.isDisliked ? 'active' : ''}
                                  onClick={(e) => {
                                    setRejectModalXY({
                                      pageX: e.pageX,
                                      pageY: e.pageY,
                                    });
                                    setIsReject(item.isDisliked);
                                    setHandleRejectModal(item.expert.id);
                                  }}
                                >
                                  <svg width="13.2px" height="13.2px">
                                    <use xlinkHref={`${sprite}#dislike`} />
                                  </svg>
                                  <svg width="13.2px" height="13.2px" className="active-svg">
                                    <use xlinkHref={`${sprite}#dislike-active`} />
                                  </svg>
                                </button>
                                <button
                                  type="button"
                                  className={item.isLiked ? 'active' : ''}
                                  onClick={(e) => {
                                    setFavoriteModalXY({
                                      pageX: e.pageX,
                                      pageY: e.pageY,
                                    });
                                    setIsFavorite(item.isLiked);
                                    setHandleFavoriteModal(item.expert.id);
                                  }}
                                >
                                  <svg width="13.2px" height="13.2px">
                                    <use xlinkHref={`${sprite}#like`} />
                                  </svg>
                                  <svg width="13.2px" height="13.2px" className="active-svg">
                                    <use xlinkHref={`${sprite}#like-active`} />
                                  </svg>
                                </button>
                              </div>
                              {item.chatId ? (
                                <Link
                                  to={`/messages/${item.chatId}?expert=${item.expert.id}&project=${project.id}`}
                                  className="btn btn-primary proekt__offers__btn-chat"
                                >
                                  {translate('inMess')}
                                </Link>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary proekt__offers__btn-chat"
                                  onClick={() => handleCreateChat(item.expert.id)}
                                >
                                  {translate('inMess')}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="proekt__content__tags">
                          {item.expert.secondCategories.length > 0 &&
                            item.expert.secondCategories.map((cat) => (
                              <div className="proekt__content__tags__item" key={cat.id}>
                                {cat.name}
                              </div>
                            ))}
                          {item.expert.thirdCategories.length > 0 &&
                            item.expert.thirdCategories.map((cat) => (
                              <div className="proekt__content__tags__item" key={cat.id}>
                                {cat.name}
                              </div>
                            ))}
                        </div>
                        <div className="proekt__content__bot">
                          <div className="proekt__location">
                            <svg width="14" height="20">
                              <use xlinkHref={`${sprite}#location`} />
                            </svg>
                            {item.expert.country && item.expert.country.name},{' '}
                            {item.expert.city && item.expert.city.name}
                          </div>
                        </div>
                        {showDetail.includes(item.expert.id) && !item.isInvited && (
                          <div className="proekt__offers__detail mt-2">
                            <div className="my-offers__bot__title">
                              {translate('My-project.letter')}
                            </div>
                            <div className="my-offers__bot__text">{item.transmittalLetter}</div>
                            {item.screeningQuestions && (
                              <>
                                <div className="my-offers__bot__title">
                                  {translate('My-project.askLatter')}
                                </div>
                                {item.screeningQuestions.map((scr) => (
                                  <div className="my-offers__src" key={scr.id}>
                                    <div className="my-offers__src__title">{scr.text}</div>
                                    <div className="my-offers__bot__text">{scr.pivot.answer}</div>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        )}

                        <div className="proekt__offers__bot">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => {
                              dispatch(setExpertCurrent(item));
                              dispatch(setShowChooseExpertModal(true));
                            }}
                          >
                            {translate('My-project.chooseExicutor')}
                          </button>
                          {!item.isInvited && (
                            <>
                              {showDetail.includes(item.expert.id) ? (
                                <button
                                  className="proekt__chat"
                                  type="button"
                                  onClick={() => removeDetail(item.expert.id)}
                                >
                                  {translate('showLess')}
                                  <svg width="44" height="21">
                                    <use xlinkHref={`${sprite}#detail`} />
                                  </svg>
                                </button>
                              ) : (
                                <button
                                  className="proekt__chat"
                                  type="button"
                                  onClick={() => addDetail(item.expert.id)}
                                >
                                  {translate('detail')}
                                  <svg width="44" height="21">
                                    <use xlinkHref={`${sprite}#detail`} />
                                  </svg>
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="mt-2">{translate('My-project.emptyRej')}</div>
                  )}
                </div>
                {waiterMore ? (
                  <Loader />
                ) : (
                  <>
                    {projectRejectedCandidatesPag.current_page !==
                      projectRejectedCandidatesPag.last_page && (
                      <div className="proekt__more">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={loadMoreRejectedCandidates}
                        >
                          {translate('My-project.addMore')}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}

        {tab === 4 && (
          <div style={{ minHeight: '100px' }}>
            {waiter ? (
              <Loader />
            ) : (
              <>
                <div className="proekt__content__wrap">
                  {invitedExperts.length > 0 ? (
                    invitedExperts.map((item) => (
                      <div
                        className={`proekt__list__item ${item.isDeclined ? 'disabled' : ''}`}
                        key={item.id}
                      >
                        <div className="proekt__detail__flex">
                          <div className="left">
                            <div className="proekt__content__top">
                              <div className="proekt__content__left">
                                <div className="proekt__content__person proekt__content__person--list">
                                  <Link
                                    className="proekt__content__avatar"
                                    to={`/expert/${item.expert.slug}`}
                                    style={{
                                      backgroundImage: `url(${
                                        item.expert.avatar || avatarDefaultComp
                                      })`,
                                    }}
                                  />
                                  <div className="proekt__content__person__right">
                                    <Link
                                      to={`/expert/${item.expert.slug}`}
                                      className="proekt__content__person__name"
                                    >
                                      {item.expert.profileName}
                                    </Link>
                                    <div className="proekt__tags">
                                      {item.isInvited ? (
                                        <>
                                          {item.expert.costPerProject && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.expert.costPerProject} {translate('grnPr')}
                                              </span>
                                            </div>
                                          )}

                                          {item.expert.costPerHour && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.expert.costPerHour} {translate('grnHour')}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item.bid && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.bid} {translate('grnPr')}
                                              </span>
                                            </div>
                                          )}
                                          {item.completionTime && (
                                            <div className="proekt__tags__item">
                                              <span className="tags-point">
                                                {item.completionTime} {translate('days')}
                                              </span>
                                            </div>
                                          )}
                                        </>
                                      )}

                                      {item.expert.businessCategories.length > 0 &&
                                        item.expert.businessCategories.map((cat) => (
                                          <div className="proekt__tags__item" key={cat.id}>
                                            <span className="tags-point">{cat.name}</span>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {item.expert.services && (
                              <div className="proekt__content__text">{item.expert.services}</div>
                            )}
                          </div>
                          <div className="right">
                            <div className="proekt__offers__right">
                              {!item.isDeclined && (
                                <div className="proekt__actios">
                                  <button
                                    type="button"
                                    className={item.isDisliked ? 'active' : ''}
                                    onClick={(e) => {
                                      setRejectModalXY({
                                        pageX: e.pageX,
                                        pageY: e.pageY,
                                      });
                                      setIsReject(item.isDisliked);
                                      setHandleRejectModal(item.expert.id);
                                    }}
                                  >
                                    <svg width="13.2px" height="13.2px">
                                      <use xlinkHref={`${sprite}#dislike`} />
                                    </svg>
                                    <svg width="13.2px" height="13.2px" className="active-svg">
                                      <use xlinkHref={`${sprite}#dislike-active`} />
                                    </svg>
                                  </button>
                                  <button
                                    type="button"
                                    className={item.isLiked ? 'active' : ''}
                                    onClick={(e) => {
                                      setFavoriteModalXY({
                                        pageX: e.pageX,
                                        pageY: e.pageY,
                                      });
                                      setIsFavorite(item.isLiked);
                                      setHandleFavoriteModal(item.expert.id);
                                    }}
                                  >
                                    <svg width="13.2px" height="13.2px">
                                      <use xlinkHref={`${sprite}#like`} />
                                    </svg>
                                    <svg width="13.2px" height="13.2px" className="active-svg">
                                      <use xlinkHref={`${sprite}#like-active`} />
                                    </svg>
                                  </button>
                                </div>
                              )}

                              {item.chatId ? (
                                <Link
                                  to={`/messages/${item.chatId}?expert=${item.expert.id}&project=${project.id}`}
                                  className="btn btn-primary proekt__offers__btn-chat"
                                >
                                  {translate('inMess')}
                                </Link>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary proekt__offers__btn-chat"
                                  onClick={() => handleCreateChat(item.expert.id)}
                                >
                                  {translate('inMess')}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="proekt__content__tags">
                          {item.expert.secondCategories.length > 0 &&
                            item.expert.secondCategories.map((cat) => (
                              <div className="proekt__content__tags__item" key={cat.id}>
                                {cat.name}
                              </div>
                            ))}
                          {item.expert.thirdCategories.length > 0 &&
                            item.expert.thirdCategories.map((cat) => (
                              <div className="proekt__content__tags__item" key={cat.id}>
                                {cat.name}
                              </div>
                            ))}
                        </div>
                        <div className="proekt__content__bot">
                          <div className="proekt__location">
                            <svg width="14" height="20">
                              <use xlinkHref={`${sprite}#location`} />
                            </svg>
                            {item.expert.country && item.expert.country.name},{' '}
                            {item.expert.city && item.expert.city.name}
                          </div>
                        </div>

                        <div className="proekt__offers__bot">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            disabled={!item.canChoose}
                            onClick={() => {
                              dispatch(setExpertCurrent(item));
                              dispatch(setShowChooseExpertModal(true));
                            }}
                          >
                            {item.isDeclined ? (
                              translate('My-project.chooseExicutorDeclined')
                            ) : (
                              <>
                                {item.canChoose
                                  ? translate('My-project.chooseExicutor')
                                  : translate('My-project.chooseExicutorNot')}
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>{translate('My-project.emptyInvite')}</>
                  )}
                </div>
                {waiterMore ? (
                  <Loader />
                ) : (
                  <>
                    {invitedExpertsPag.current_page !== invitedExpertsPag.last_page && (
                      <div className="proekt__more">
                        <button type="button" className="btn btn-primary" onClick={loadMoreInvited}>
                          {translate('My-project.addMore')}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>

      {handleRejectModal !== 0 && (
        <RejectExpertModal
          handleRejectModal={handleRejectModal}
          rejectModalXY={rejectModalXY}
          setHandleRejectModal={setHandleRejectModal}
          dispatch={handleReject}
          isReject={isReject}
        />
      )}

      {handleFavoriteModal !== 0 && (
        <FavoriteExpertModal
          handleFavoriteModal={handleFavoriteModal}
          favoriteModalXY={favoriteModalXY}
          setHandleFavoriteModal={setHandleFavoriteModal}
          dispatch={handleFavorite}
          isFavorite={isFavorite}
        />
      )}
    </>
  );
};

export default Offers;
