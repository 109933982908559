import './style.scss';

import { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import { BackgroundImage } from 'react-image-and-background-image-fade';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LazyLoad from 'react-lazyload';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import iconExpert from 'src/assets/img/main/icon-expert.svg';
import iconProject from 'src/assets/img/main/icon-project.svg';
import iconProject2 from 'src/assets/img/main/icon-project2.svg';
import s41 from 'src/assets/img/main/s4-1.webp';
import s42 from 'src/assets/img/main/s4-2.webp';
import s7 from 'src/assets/img/main/s7.webp';
import s7Mob from 'src/assets/img/main/s7-mob.jpg';
import video1 from 'src/assets/img/main/video1.mp4';
import video2 from 'src/assets/img/main/video2.mp4';
import imgDes from 'src/assets/img/main-img.png';
import imgMob from 'src/assets/img/main-img-mob.png';
import logo from 'src/assets/img/old-img/logo-mobile.svg';
import sprite from 'src/assets/img/sprite.svg';
import videoSm from 'src/assets/img/video/960х1704_(320x568).mp4';
import videoMd from 'src/assets/img/video/1024х768.mp4';
import videoXl from 'src/assets/img/video/3840x2160_2_1.mp4';
import { Footer } from 'src/components';
import { useTranslation, useWindowDimensions } from 'src/hooks';
import { customActions } from 'src/store/actions';
import { setFirstVisit } from 'src/store/reducers/auth.reducer';

import { Search, SubMenu } from './components';

const Main = () => {
  const dispatch = useDispatch();
  const { translate, setLanguage, language } = useTranslation();

  const [firstVisit] = useState(useSelector((state) => state.authReducer.firstVisit));

  const previewRef = useRef();
  const { width } = useWindowDimensions();
  const ifAuth = !!useSelector((state) => state.authReducer.access_token);
  const role = useSelector((state) => state.authReducer.user.role);
  const [menu, setMenu] = useState(false);

  const [tab, setTab] = useState(role || 1);

  const [spoler, setSpoler] = useState(false);
  const categories = useSelector((state) => state.projectReducer.projectExpertCategories);

  const count = useSelector((state) => state.customReducer.count);

  const [expertCat, setExpertCat] = useState(1);
  const [companyCat, setCompanyCat] = useState(3);
  const projectsLevel1 =
    categories.length > 0 && categories.filter((item) => item.id === companyCat)[0];
  const expertsLevel1 =
    categories.length > 0 && categories.filter((item) => item.id === expertCat)[0];

  const [lang, setLang] = useState(false);

  useEffect(() => {
    if (firstVisit) {
      const video = document.getElementById('mainVideo');
      video.onloadeddata = () => {
        setTimeout(() => {
          if (previewRef.current) {
            previewRef.current.classList.add('active');
          }
        }, 2500);
      };
    } else if (previewRef.current) {
      previewRef.current.classList.add('active');
    }

    document.addEventListener('click', (e) => {
      if (!e.target.closest('.header__lang')) {
        setLang(false);
      }
    });

    if (window.innerWidth < 992) {
      const mobMenuBtn = document.querySelectorAll('.header__sub__menu__item__m_btn');
      mobMenuBtn.forEach((item) => {
        item.addEventListener('click', () => {
          item.closest('.header__sub__menu__item').classList.toggle('active');
        });
      });
    }
  }, []);

  useEffect(() => {
    dispatch(customActions.getCountOnMainPage());
    dispatch(setFirstVisit());
  }, []);

  return (
    <div className="m_app">
      <header className="m_header">
        <div className="wrap">
          <div className="m_header__wrap">
            <button
              className={`mobBurger ${menu ? 'active' : ''}`}
              type="button"
              onClick={() => setMenu(!menu)}
            >
              <div className="mobBurger__line" />
              <div className="mobBurger__line" />
              <div className="mobBurger__line" />
            </button>
            <div className="m_header__logo">
              <img src={logo} alt="" />
              ExpertLand
            </div>
            <nav className="header__menu">
              <ul>
                <li>
                  <Link to="/projects">{translate('mainPage.searchWork')}</Link>
                </li>
                <li>
                  <Link to="/freelancers">{translate('mainPage.searchExpert')}</Link>
                </li>
              </ul>
            </nav>
            <nav className="header__auth">
              {ifAuth ? (
                <Link to="/profile/" className="header__auth__signUp">
                  {translate('mainPage.profile')}
                </Link>
              ) : (
                <>
                  <Link to="/auth"> {translate('mainPage.singin')}</Link>
                  <Link to="/auth/sign-up" className="header__auth__signUp">
                    {translate('mainPage.singup')}
                  </Link>
                </>
              )}
            </nav>
          </div>
        </div>
      </header>
      <nav className={`header__sub ${menu ? 'active' : ''}`}>
        <div className="wrap">
          <div className="header__sub__mob">
            <nav className="header__menu">
              <ul>
                <li>
                  <Link to="/projects">{translate('mainPage.searchWork')}</Link>
                </li>
                <li>
                  <Link to="/freelancers">{translate('mainPage.searchExpert')}</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header__sub__wrap">
            <SubMenu />
            <div className="header__lang">
              <button
                type="button"
                className="header__lang__m_btn"
                onClick={() => {
                  setLang(!lang);
                }}
              >
                <svg width="24" height="24">
                  <use xlinkHref={`${sprite}#mainLang`} />
                </svg>
                <span className="header__lang__name">{language}</span>
                <svg width="24" height="24">
                  <use xlinkHref={`${sprite}#mainArr`} />
                </svg>
              </button>
              <div className={`header__lang__toggle ${lang ? 'active' : ''}`}>
                <button
                  type="button"
                  onClick={() => {
                    setLanguage('ru');
                    window.location.reload();
                  }}
                >
                  Ru
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setLanguage('ua');
                    window.location.reload();
                  }}
                >
                  Ua
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <section className="s1">
        <div className="wrap">
          <div className="s1__wrap" ref={previewRef}>
            <div className="s1__content">
              <h1 className="s1__title">{translate('mainPage.title')}</h1>
              <Search />
              <div className="tags">
                {categories &&
                  categories.map((item) => (
                    <Link to={`/freelancers/${item.slug}`} key={item.id} className="tags__item">
                      {item.name}
                    </Link>
                  ))}
              </div>
              <div className="s1__text">{translate('mainPage.subTitle1')}</div>
              <div className="s1__link">
                <Link to="/auth/sign-up" className="m_btn">
                  {translate('mainPage.btn1')}
                </Link>
              </div>
            </div>
            {firstVisit ? (
              <div className="s1__video">
                {width > 991 && (
                  <video className="video__xl" autoPlay muted playsInline id="mainVideo">
                    <source src={videoXl} type="video/mp4" />
                  </video>
                )}

                {width > 767 && width < 992 && (
                  <video className="video__md" autoPlay muted playsInline id="mainVideo">
                    <source src={videoMd} type="video/mp4" />
                  </video>
                )}
                {width < 768 && (
                  <video className="video__sm" autoPlay muted playsInline id="mainVideo">
                    <source src={videoSm} type="video/mp4" />
                  </video>
                )}
              </div>
            ) : (
              <div className="s1__mainImg">
                {width < 768 ? (
                  <img src={imgMob} alt="main-img" />
                ) : (
                  <img src={imgDes} alt="main-img" />
                )}
              </div>
            )}

            <div className="s1__title s1__title--mob">{translate('mainPage.title')}</div>
          </div>
        </div>
      </section>
      <section className="s2">
        <div className="wrap">
          <div className="s2__wrap">
            <div className="s2__left">
              <LazyLoad>
                <video src={video1} width={452} autoPlay muted loop />
              </LazyLoad>
            </div>
            <div className="s2__right">
              <div className="s2__label">{translate('mainPage.sec2Label')}</div>
              <div className="s2__title">{translate('mainPage.sec2Title')}</div>
              <div className="s2__text">{translate('mainPage.sec2Text')}</div>
              <div className="s1__link">
                <Link to="/freelancers" className="m_btn">
                  {translate('mainPage.sec2Btn')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="s3">
        <div className="wrap">
          <div className="s3__wrap">
            <div className="s3__item">
              <div className="m__title">{translate('mainPage.sec3Title1')}</div>
              <div className="s3__text">{translate('mainPage.sec3Text1')}</div>
              <div className="s1__link">
                <Link to="/publication" className="m_btn m_btn--black">
                  {translate('mainPage.sec3Btn1')}
                </Link>
              </div>
              <LazyLoadImage
                alt="iconExpert"
                height={width > 768 ? 181 : 128}
                src={iconExpert}
                width={width > 768 ? 181 : 128}
                className="s3__img"
              />
            </div>
            <div className="s3__item">
              <div className="m__title">{translate('mainPage.sec3Title2')}</div>
              <div className="s3__text">{translate('mainPage.sec3Text2')}</div>
              <div className="s1__link">
                <Link to="/freelancers" className="m_btn m_btn--black">
                  {translate('mainPage.sec3Btn2')}
                </Link>
              </div>
              <LazyLoadImage
                alt="iconProject"
                height={width > 768 ? 181 : 128}
                src={iconProject}
                width={width > 768 ? 181 : 128}
                className="s3__img"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="s4">
        <div className="wrap">
          <div className="s4__wrap">
            <div className="s4__left">
              <div className="s4__img1">
                <LazyLoadImage src={s41} alt="img" />
                {/* <a href="https://www.freepik.com/free-photo/elegant-business-lady-trendy-black-jacket-holding-laptop-smiling-portrait-cheerful-blonde-secretary-tall-african-office-worker_10483980.htm">
                  Image by lookstudio
                </a>{' '}
                on Freepik */}
              </div>

              <div className="s4__left__text">{translate('mainPage.sec4Text')}</div>
              <div className="s4__img2">
                <LazyLoadImage alt="s42" height={395} src={s42} width={319} />
                {/* <a href="https://www.freepik.com/free-photo/two-business-partners-working-office_11601420.htm#query=business%20couple&position=6&from_view=keyword&track=ais">Image by senivpetro</a> on Freepik */}
              </div>
            </div>
            <div className="s4__right">
              <div className="s4__label">{translate('mainPage.sec4Label')}</div>
              <div className="m__title">{translate('mainPage.sec4Title')}</div>
              <div className="s4__video">
                <LazyLoad>
                  <video src={video2} autoPlay muted loop />
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="s5">
        <div className="wrap">
          <div className="s5__title">{translate('mainPage.sec5Title')}</div>
          <div className="s5__wrap">
            <div className="s5__col">
              <LazyLoadImage
                alt="iconExpert"
                height={width > 768 ? 181 : 128}
                src={iconExpert}
                width={width > 768 ? 181 : 128}
                className="s5__img"
              />
              <CountUp start={0} end={count.expertCount} duration={3} delay={0} useEasing={false}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} className="s5__cifra" />
                  </VisibilitySensor>
                )}
              </CountUp>
              <div className="s5__text">{translate('mainPage.sec5Text1')}</div>
            </div>
            <div className="s5__col">
              <LazyLoadImage
                alt="iconProject"
                height={width > 768 ? 181 : 128}
                src={iconProject}
                width={width > 768 ? 181 : 128}
                className="s5__img"
              />
              <CountUp start={0} end={count.companyCount} duration={3} delay={0} useEasing={false}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} className="s5__cifra" />
                  </VisibilitySensor>
                )}
              </CountUp>
              <div className="s5__text">{translate('mainPage.sec5Text2')}</div>
            </div>
            <div className="s5__col">
              <LazyLoadImage
                alt="iconProject"
                height={width > 768 ? 181 : 128}
                src={iconProject2}
                width={width > 768 ? 181 : 128}
                className="s5__img"
              />
              <CountUp start={0} end={count.projectCount} duration={3} delay={0} useEasing={false}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} className="s5__cifra" />
                  </VisibilitySensor>
                )}
              </CountUp>
              <div className="s5__text">{translate('mainPage.sec5Text3')}</div>
            </div>
          </div>
        </div>
      </section>
      <section className="s6">
        <div className="wrap">
          <div className="s6__title">{translate('mainPage.sec6Title')}</div>
          <div className="s6__tabs">
            <div className="s6__tabs__wrap">
              {(!role || role === 2) && (
                <button
                  type="button"
                  className={`${tab === 2 ? 'active' : ''}`}
                  onClick={() => setTab(2)}
                >
                  {translate('mainPage.sec6Tab1')}
                </button>
              )}
              {(!role || role === 1) && (
                <button
                  type="button"
                  className={`${tab === 1 ? 'active' : ''}`}
                  onClick={() => setTab(1)}
                >
                  {translate('mainPage.sec6Tab2')}
                </button>
              )}
            </div>
          </div>
          {(!role || role === 2) && (
            <div className={`s6__tabcontent ${tab === 2 ? 'active fadeIn' : ''}`}>
              <div className="s6__col">
                <div className="s6__col__title">{translate('mainPage.sec6Title1')}</div>
                <div className="s6__col__text">{translate('mainPage.sec6Text1')}</div>
                <div className="s1__link">
                  <Link to="/auth/sign-up" className="m_btn m_btn--black">
                    {translate('mainPage.sec6Btn1')}
                  </Link>
                </div>
                <div className="s6__num">01</div>
              </div>
              <div className="s6__col">
                <div className="s6__col__title">{translate('mainPage.sec6Title2')}</div>
                <div className="s6__col__text">{translate('mainPage.sec6Text2')}</div>
                <div className="s1__link">
                  <Link to="/publication/" className="m_btn m_btn--black">
                    {translate('mainPage.sec6Btn2')}
                  </Link>
                </div>
                <div className="s6__num">02</div>
              </div>
              <div className="s6__col">
                <div className="s6__col__title">{translate('mainPage.sec6Title3')}</div>
                <div className="s6__col__text">{translate('mainPage.sec6Text3')}</div>
                <div className="s1__link">
                  <Link to="/freelancers" className="m_btn m_btn--black">
                    {translate('mainPage.sec6Btn3')}
                  </Link>
                </div>
                <div className="s6__num">03</div>
              </div>
              <div className="s6__col">
                <div className="s6__col__title">{translate('mainPage.sec6Title4')}</div>
                <div className="s6__col__text">{translate('mainPage.sec6Text4')}</div>
                <div className="s1__link">
                  <Link to="/my-projects/" className="m_btn m_btn--black">
                    {translate('mainPage.sec6Btn4')}
                  </Link>
                </div>
                <div className="s6__num">04</div>
              </div>
            </div>
          )}
          {(!role || role === 1) && (
            <div className={`s6__tabcontent ${tab === 1 ? 'active fadeIn' : ''}`}>
              <div className="s6__col">
                <div className="s6__col__title">{translate('mainPage.sec6Title1')}</div>
                <div className="s6__col__text">{translate('mainPage.sec6Text12')}</div>
                <Link to="/auth/sign-up/" className="m_btn m_btn--black">
                  {translate('mainPage.sec6Btn1')}
                </Link>
                <div className="s6__num">01</div>
              </div>
              <div className="s6__col">
                <div className="s6__col__title">{translate('mainPage.sec6Title22')}</div>
                <div className="s6__col__text">{translate('mainPage.sec6Text22')}</div>
                <Link to="/user/" className="m_btn m_btn--black">
                  {translate('mainPage.sec6Btn22')}
                </Link>
                <div className="s6__num">02</div>
              </div>
              <div className="s6__col">
                <div className="s6__col__title">{translate('mainPage.sec6Title33')}</div>
                <div className="s6__col__text">{translate('mainPage.sec6Text33')}</div>
                <Link to="/otbor/" className="m_btn m_btn--black">
                  {translate('mainPage.sec6Btn33')}
                </Link>
                <div className="s6__num">03</div>
              </div>
              <div className="s6__col">
                <div className="s6__col__title">{translate('mainPage.sec6Title44')}</div>
                <div className="s6__col__text">{translate('mainPage.sec6Text44')}</div>
                <Link to="/projects/" className="m_btn m_btn--black">
                  {translate('mainPage.sec6Btn44')}
                </Link>
                <div className="s6__num">04</div>
              </div>
            </div>
          )}
        </div>
      </section>
      {/* <a href="https://www.freepik.com/free-photo/bussiness-people-working-team-office_8828168.htm">Image by senivpetro</a> on Freepik */}
      <BackgroundImage className="s7" src={width > 767 ? s7 : s7Mob}>
        <div className="wrap">
          <div className="s7__wrap">
            <div className="s7__title">{translate('mainPage.sec7Title')}</div>
            <div className="s7__text">{translate('mainPage.sec7Text')}</div>
            <div className="s7__text">{translate('mainPage.sec7Text2')}</div>
          </div>
        </div>
      </BackgroundImage>
      {(!role || role === 2) && (
        <section className="s8">
          <div className="wrap">
            <div className="s8__title">{translate('mainPage.sec8Title1')}</div>
            <div className="s8__wrap">
              <div className="s8__left">
                {categories.length > 0 &&
                  categories.map((item) => (
                    <div key={item.id}>
                      <button
                        className={item.id === expertCat ? 'active' : ''}
                        type="button"
                        onClick={() => setExpertCat(item.id)}
                      >
                        {item.name}
                      </button>
                      {width < 768 && item.id === expertCat && (
                        <div className="s8__right">
                          <ul>
                            {item.child.map((child) => (
                              <li key={child.id}>
                                <Link
                                  to={`/freelancers/${expertsLevel1 ? expertsLevel1.slug : ''}/${
                                    child.slug
                                  }`}
                                >
                                  {child.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              <div className="s8__right">
                <ul>
                  {categories.length > 0 &&
                    categories
                      .filter((item) => item.id === expertCat)[0]
                      .child.map((child) => (
                        <li key={child.id}>
                          <Link
                            to={`/freelancers/${expertsLevel1 ? expertsLevel1.slug : ''}/${
                              child.slug
                            }`}
                          >
                            {child.name}
                          </Link>
                        </li>
                      ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}
      {(!role || role === 1) && (
        <section className="s8">
          <div className="wrap">
            <div className="s8__title">{translate('mainPage.sec8Title2')}</div>
            <div className="s8__wrap">
              <div className="s8__left">
                {categories.length > 0 &&
                  categories.map((item) => (
                    <div key={item.id}>
                      <button
                        className={item.id === companyCat ? 'active' : ''}
                        type="button"
                        onClick={() => setCompanyCat(item.id)}
                      >
                        {item.name}
                      </button>
                      {width < 768 && item.id === companyCat && (
                        <div className="s8__right">
                          <ul>
                            {item.child.map((child) => (
                              <li key={child.id}>
                                <Link
                                  to={`/projects/${projectsLevel1 ? projectsLevel1.slug : ''}/${
                                    child.slug
                                  }`}
                                >
                                  {child.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              <div className="s8__right">
                <ul>
                  {categories.length > 0 &&
                    categories
                      .filter((item) => item.id === companyCat)[0]
                      .child.map((child) => (
                        <li key={child.id}>
                          <Link
                            to={`/projects/${projectsLevel1 ? projectsLevel1.slug : ''}/${
                              child.slug
                            }`}
                          >
                            {child.name}
                          </Link>
                        </li>
                      ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="s9">
        <div className="wrap">
          <div className="s9__wrap">
            <div className="s9__title">{translate('mainPage.sec9Title')}</div>
            <div className={`content ${spoler ? 'active' : ''}`}>
              <div dangerouslySetInnerHTML={{ __html: translate('mainPage.sec9Text') }} />
            </div>
            <div className="s9__btn">
              <button type="button" onClick={() => setSpoler(!spoler)}>
                {!spoler ? translate('mainPage.sec9Show') : translate('mainPage.sec9Hide')}
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Main;
